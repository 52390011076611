export const FirebaseConfig = {
	"projectId": "irielift-207b0",
	"appId": "1:833016631204:web:45050cc7f18be1231bfb31",
	"databaseURL": "https://irielift-207b0-default-rtdb.firebaseio.com",
	"storageBucket": "irielift-207b0.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyCp5hOvPH4fGeTmS5mLGf7JGymPkARaLQI",
	"authDomain": "irielift-207b0.firebaseapp.com",
	"messagingSenderId": "833016631204",
	"measurementId": "G-B0NZ72T2SH"
};